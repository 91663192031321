.event-container {
    position: relative;
    display: inline-block;
}

.event-title {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    font-size: 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.event-container:hover .event-title {
    opacity: 1;
}
