@keyframes fade-swipe-in {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.course-item {
    opacity: 0;
    flex: 1;
    min-width: 400px;
    max-width: 550px;
    animation: fade-swipe-in 500ms ease-out forwards;
}