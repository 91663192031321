.cc-images {
    transition: transform .5s ease;
}

.cc-images img {
    border-radius: 15px;
    width: 700px;
}

.cc-images > div:not(.blur) img {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .75);
    transition: box-shadow .75s;
}

@media (max-width: 991px) {
    .cc-images img {
        width: 500px;
    }
}

@media (max-width: 767px) {
    .cc-images img {
        width: 300px;
    }
}

.blur {
    filter: blur(10px);
    opacity: .75;
}

.cc-images > div {
    transition: filter .5s, opacity .5s;
}

.class-info {
    font-size: 1.2rem;
}