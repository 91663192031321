.project-list-ref.active .li-title {
    background-color: var(--bs-primary-bg-subtle) !important;
    transition: background-color 0.3s ease-in-out;
}

.project-list-ref.active .icon-active {
    display: inline !important;
}

.project-list-ref.active .icon-inactive {
    display: none !important;
}

.sticky-scrollspy-nav {
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 767px) {
    .sticky-scrollspy-nav {
        margin-top: 208px;
    }
}