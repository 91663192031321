.section-title h6::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    border-top: 2px dashed #F14D5D;
}

#introVideo {
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.75), 0 20px 50px rgba(0, 0, 0, 0.75);
}


/* MILESTONES */

.milestone-arrow {
    min-width: 10em;
    max-width: 10em;
}

@media (max-width: 767px) {
    .milestone-arrow {
        transform: rotate(90deg) !important;
    }
}

.milestonesElement {
    opacity: 0;
    transform: translateX(-150px);
    transition: transform .75s ease-in-out, opacity .75s ease-in-out;
}


.partnerschool-section {
    min-height: 650px;
}

.image-container {
    transition: opacity 1s ease-in-out;
}

.image-container > img {
    max-height: 450px;
}

.image-container.transitioning {
    opacity: 0;
}


/* width ≥1200px */
@media (min-width: 1600px) {
    .p-xl-signs {
        padding: 3em !important;
    }
}