#untisImage {
    box-shadow: -30px 60px 125px -25px rgb(41 48 53 / 40%), -16px 37px 75px -37px rgb(0 0 0 / 40%);;
}


/* EXAMDATES */
.classBox {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #fff;
}

.subjectBox {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}

.examDates > .row > .col-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}

.disabled {
    background-color: #f8f8f8 !important;
}

.disabled.bg-light {
    background-color: rgb(244, 247, 253) !important;
}